<template>
  <div class="content">
    <Loading v-if="isLoading" />

    <Nav
      class="left"
      :isCollapse="isCollapse"
      :navData="filterNavList"
      :active="active"
      @clickNav="clickNav"
      :style="{ width: leftWidth }"
    ></Nav>

    <div class="right" :style="{ width: rightWidth }">
      <Header :role="2" style="header" @collapse="collapse"></Header>
      <div class="content_right">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/loading/index";
import Header from "@/components/header/index.vue";
import Nav from "@/components/navMenu/index.vue";
import { mapState, mapMutations } from "vuex";
export default {
  components: {
    Nav,
    Header,
    Loading,
  },

  async mounted() {
    /* 根据权限初始化导航 迁移到了store*/
    // this.navData = JSON.parse(JSON.stringify(this.navList));
    // this.filterFun(this.navData, this.roleList);
    // this.$store.commit("updataFilterNavList", this.navData);
    /* 初始化导航高亮 */
    // this.findFun(
    //   this.filterNavList,
    //   window.location.hash.substring(1, window.location.hash.length)
    // );
    // if (!this.active) {
    //   this.$router.push({ name: "Errow" });
    //   return;
    // }
    this.isLoading = true;
    try {
      /* Perform any initialization or data fetching here */
      // Example: await this.fetchNavData();

      // Simulate an async operation
      await new Promise((resolve) => setTimeout(resolve, 2000));
    } catch (error) {
      console.error("Error during initialization:", error);
    } finally {
      this.isLoading = false;
    }
  },

  data() {
    return {
      isCollapse: false, // 是否折叠导航栏
      leftWidth: "220px",
      rightWidth: "calc(100% - 250px)",
      routerName: "",
      roleList: [], // 权限
      isLoading: false, // 加载状态
    };
  },
  computed: {
    ...mapState(["userInfo", "filterNavList", "active"]),
  },
  watch: {},
  methods: {
    ...mapMutations(["updataActive"]),
    clickNav(item) {
      // this.active = item.id;
      this.updataActive(item.id);
      this.routerName = item.routerName;
      this.$router.push({ name: item.routerName });
    },
    collapse(isCollapse) {
      this.isCollapse = isCollapse;
      if (isCollapse) {
        this.leftWidth = "65px";
        this.rightWidth = "calc(100% - 105px)";
      } else {
        this.leftWidth = "220px";
        this.rightWidth = "calc(100% - 260px)";
      }
    },
    /*  找到导航 id */
    // findFun(navData, path) {
    //   for (let index = 0; index < navData.length; index++) {
    //     if (navData[index]?.path === path) {
    //       this.active = navData[index]?.id;
    //     }
    //     if (
    //       navData[index]?.children &&
    //       navData[index]?.children.length > 0 &&
    //       navData[index]?.path !== path
    //     ) {
    //       this.findFun(navData[index].children, path);
    //     }
    //   }
    // },

    /* 筛选全量导航 */
    // filterFun(navData, roleList) {
    //   for (var index = navData.length - 1; index >= 0; index--) {
    //     if (
    //       navData[index]?.children &&
    //       navData[index]?.children.length > 0 &&
    //       roleList?.indexOf(navData[index].id) >= 0
    //     ) {
    //       this.filterFun(navData[index].children);
    //     }
    //     if (roleList?.indexOf(navData[index].id) < 0) {
    //       navData.splice(index, 1);
    //     }
    //   }
    // },
  },
};
</script>

<style>
.content {
  background: white;
  width: 100%;
  margin: 0 auto;
  display: flex;
  min-width: 800px;
}
.right {
  height: 100vh;
  margin: 0 auto;
}

.header {
  width: 100%;
}
.content_right {
  width: 100%;
  height: calc(100% - 10vh);
  background: #fff;
  padding: 30px 20px;
}

.el-dialog {
  border-radius: 20px;
  padding: 30px 20px;
}

.el-input__inner:focus {
  border-color: #afb68d !important;
}

.el-textarea .el-textarea__inner:focus {
  border-color: #afb68d !important;
}

.addBtn {
  background: #bcc39b !important;
  border: none;
  font-size: 15px;
  border-radius: 7px;
  transition: 0.3s;
}

.addBtn:hover {
  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.2);
}

.cancelBtn {
  background: #dcdcdc;
  color: white !important;
  font-size: 15px;
  border-radius: 7px;
  border: none;
  transition: 0.3s;
}

.cancelBtn:hover {
  background: #ff4d4d;
  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar {
  display: none;
}

.theTagGreen {
  background: #afb86d !important;
  border: none;
  padding: 0 10px;
}
</style>
