<template>
  <div class="loadingBkg">
    <div ref="animationContainer" class="animation-container"></div>
  </div>
</template>

<script>
import lottie from "lottie-web";
import animationData from "../../image/loading.json";

export default {
  data() {
    return {
      animationContainer: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.initAnimation();
    });
  },
  methods: {
    initAnimation() {
      this.animationContainer = this.$refs.animationContainer;
      lottie.loadAnimation({
        container: this.animationContainer,
        renderer: "svg", // Use 'svg' renderer for better performance
        loop: true,
        autoplay: true,
        animationData: animationData,
      });
    },
  },
};
</script>

<style scoped lang="less">
.loadingBkg {
  position: fixed;
  z-index: 10000;
  background-color: white;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.animation-container {
  width: 100px;
  height: 100px;
}
</style>
