<template>
  <div class="nav">
    <div class="logo">
      <div v-if="!isCollapse" class="title">YIHOI</div>
    </div>
    <el-menu
      :default-active="active"
      class="el-menu-vertical-demo"
      @open="handleOpen"
      @close="handleClose"
      :collapse="isCollapse"
      :unique-opened="true"
      background-color="#ffffff"
      text-color="#333"
      active-text-color="#ffcd4d"
    >
      <template v-for="item in navData">
        <!-- 一级导航 -->
        <el-menu-item
          :index="item.id"
          v-if="!item.children"
          :key="item.id"
          @click="itemBtn(item)"
        >
          <i :class="item.icon"></i>
          <span slot="title">{{ item.label }}</span>
        </el-menu-item>
        <!-- 二级导航 -->
        <el-submenu :index="item.id" v-else :key="item.id + 1">
          <template slot="title">
            <i :class="item.icon"></i>
            <span slot="title">{{ item.label }}</span>
          </template>
          <div
            v-for="(item_next, index_next) in item.children"
            :key="index_next"
          >
            <el-menu-item
              :index="item_next.id"
              v-if="!item_next.children"
              @click="itemBtn(item_next)"
            >
              <span slot="title">{{ item_next.label }}</span>
            </el-menu-item>
            <el-submenu :index="item_next.id" v-else>
              <span slot="title">{{ item_next.label }}</span>
              <el-menu-item
                v-for="(item_next_next, index_next_next) in item_next.children"
                :key="index_next_next"
                :index="item_next_next.id"
                @click="itemBtn(item_next_next)"
                >{{ item_next_next.label }}</el-menu-item
              >
            </el-submenu>
          </div>
        </el-submenu>
      </template>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: "Nav",
  components: {},
  props: {
    isCollapse: {
      type: Boolean,
      default: true,
    },
    navData: {
      type: Array,
    },
    active: {
      type: String,
    },
  },
  data() {
    return {};
  },
  methods: {
    itemBtn(item, index) {
      this.$emit("clickNav", item, index);
    },
    handleOpen(key, keyPath) {
      // console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath);
    },
  },
};
</script>

<style scoped lang="less">
.nav {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  background: #ffffff;
  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333333;
    font-size: 35px;
    font-weight: 600;
    letter-spacing: 5px;
    padding: 10px;
    cursor: default;
  }
}

.el-menu-vertical-demo {
  background: #ffffff;
  .el-menu-item {
    color: #333333;
    border-radius: 10px;
    margin: 5px 0px;
    transition: background-color 0.3s ease, color 0.3s ease;
    display: flex;
    align-items: center;
  }
  .el-menu-item.is-active {
    background-color: #afb68d !important;
    color: #ffffff !important;
    border-radius: 10px;
  }
  .el-menu-item:hover {
    background-color: #e9edd8 !important;
  }
}

::v-deep .el-submenu__title {
  border-radius: 10px !important;
  margin: 5px 0px;
  transition: background-color 0.3s ease, color 0.3s ease;
  display: flex;
  align-items: center;
}

::v-deep  .el-submenu__title:hover {
  background-color: #ebede0 !important;
}

.el-menu {
  border-right: none;
  margin: 5px 10px;
}
</style>
