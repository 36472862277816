<template>
  <div class="header">
    <!-- logo -->
    <div class="logo" @click="collapseBtn">
      <img
        src="@/image/nav_icon/daohang.png"
        style="width: 100%;"
        v-if="isCollapse"
      />
      <img
        src="@/image/nav_icon/zhedie.png"
        alt=""
        srcset=""
        style="width: 100%;"
        v-else
      />
    </div>
    <div class="right">
      <div class="right_dom1" @click="tuichuBtn">
        <img
          src="@/image/user.png"
          style="width: 35px;height:35px; border-radius: 50%;"
        />
        <!-- 身份 -->
        <div>
          {{
            userInfo.grade === 4
              ? "管理员"
              : !userInfo.grade && userInfo.sup_username
              ? "子账号"
              : userInfo.grade === 1 ||
                userInfo.grade === 2 ||
                userInfo.grade === 3
              ? "管理员账号"
              : ""
          }}
          {{ userInfo.sup_username ? userInfo.sup_username : "请登录账户" }}
        </div>
        <!-- 切换账户 -->
        <!-- <div class="tuichuBtn">
          <el-tooltip
            class="item"
            effect="dark"
            content="退出账号"
            placement="bottom"
          >
            <img
              src="@/image/header/icon.png"
              alt=""
              srcset=""
              style="width: 15px;height:15px;"
            />
          </el-tooltip>
        </div> -->
      </div>
      <!-- 切换全屏 -->
      <img
        class="handleFullScreen"
        @click="handleFullScreen"
        :src="
          !isFullscreen
            ? require('../../image/header/quanping.png')
            : require('../../image/header/quxiaoquanping.png')
        "
        alt=""
        srcset=""
        style="width: 40px;height:40px;display: block;"
      />
    </div>
  </div>
</template>

<script>
import { loginOut } from "@/services/user/index";
import { mapState } from "vuex";
import screenfull from "screenfull";
export default {
  props: {
    role: {
      type: Number,
    },
  },
  data() {
    return {
      isCollapse: false,
      isFullscreen: false,
    };
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  mounted() {
    // screenfull在这里有一个on方法，监控事件的触发
    screenfull.on("change", () => {
      this.isFullscreen = screenfull.isFullscreen;
    });
  },
  methods: {
    collapseBtn() {
      this.isCollapse = !this.isCollapse;
      this.$emit("collapse", this.isCollapse);
    },
    /* 退出账户 */
    tuichuBtn() {
      console.log("this.userInfo.id", this.userInfo.id);
      this.$confirm("是否确认退出?", "退出登录", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$router.push({ name: "Login" });
        loginOut({ id: this.userInfo.id }).then((resp) => {
          this.$message({
            type: "success",
            message: "已退出登录!",
          });
        });
      });
    },
    /* 全屏 */
    handleFullScreen() {
      if (!screenfull.enabled) {
        this.$message.info("您的浏览器版本过低，不支持全屏浏览");
        return false;
      }
      screenfull.toggle();
      this.isFullscreen = !this.isFullscreen;
    },
  },
};
</script>

<style scoped lang="less">
.header {
  position: relative;
  height: 70px;
  background: #fff;
  line-height: 60px;
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  align-items: center;
}
.header > .logo {
  margin-left: 20px;
  width: 22px;
}
.header > .logo:hover {
  cursor: pointer;
}

/* 用户 */
.right {
  width: 300px;
  position: absolute;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.right_dom1 {
  font-weight: 600;
  width: 195px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.3s;
  img {
    margin-right: 10px;
  }
}
.right_dom1:hover {
  cursor: pointer;
  color: #afb86d;
}
/* 退出登录 */
.tuichuBtn {
  margin: 0 5px;
}
.tuichuBtn:hover {
  cursor: pointer;
}
.handleFullScreen:hover {
  cursor: pointer;
}
</style>
